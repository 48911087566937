import React from 'react';
import Section from '../../components/section';
import {PageHero} from '../../components/page-hero';
import Layout from '../../components/layout/layout';
import Tabs from '../../components/tabs';
import './styles.scss';

const UseCases = () => {
  return (
    <Layout>
      <PageHero title="Hexowatch use cases" subtitle="" />
      <Section>
        <div style={{margin: 'auto'}}>
          <Tabs />
        </div>
        <div className="use-cases">
          <a href="/use-cases/monitor-website-security-threads" className="title">
            Monitor your website for security threats
          </a>
          <a href="/use-cases/monitor-competitors" className="title">
            Monitor your competitors
          </a>
          <a href="/use-cases/monitor-requests-for-proposals" className="title">
            Find out when websites publish new requests for proposals & be the first to respond to RFP Alerts
          </a>

          <a href="/use-cases/monitor-products-availability" className="title">
            Receive alerts when products become available or go out of stock
          </a>
          <a href="/use-cases/monitor-legal-compliance" className="title">
            Monitor pages for legal & corporate compliance
          </a>
          <a href="/use-cases/monitor-press-mentions" className="title">
            Monitor Press Mentions
          </a>

          <a href="/use-cases/track-page-changes" className="title">
            Tracking changes on websites
          </a>
          <a href="/use-cases/financial-compliance-monitoring" className="title">
            Financial services compliance monitoring
          </a>
          <a href="/use-cases/know-when-event-tickets-sale" className="title">
            Receive alerts when event tickets go on sale
          </a>
        </div>
      </Section>
    </Layout>
  );
};

export default UseCases;
